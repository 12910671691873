<template>
  <div class="activity">
    <Header />
    <HeaderBottomOrders />
    <div class="investment-activity">
      <b-container class="activity-container">
        <div class="activity-title mb-4">
          <b-row class="justify-content-center">
            <h1>Aktivitas</h1>
          </b-row>
        </div>
        <div>
          <div class="activity-tab">
            <b-row class="justify-content-center">
              <div class="on-proccess mx-4">
                <b-col>
                  <h3><b-link href="#">Dalam Proses</b-link></h3>
                </b-col>
              </div>
              <div class="finish mx-4">
                <b-col>
                  <h3><b-link to="/orders/finish">Selesai</b-link></h3>
                </b-col>
              </div>
            </b-row>
          </div>
        </div>
      </b-container>
    </div>
    <div class="list-activity">
      <b-container class="list-activity-container">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-row class="my-4 p-4">
              <div class="col-lg-3 col-md-6">
                <b-row>
                  <div class="col-lg-2 col-md-6 mb-4">
                    <b-skeleton type="avatar"></b-skeleton>
                  </div>
                  <b-col>
                    <b-skeleton width="80%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="40%"></b-skeleton>
                  </b-col>
                </b-row>
              </div>
              <div class="col-lg-2 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
              </div>
              <div class="col-lg-3 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
              </div>
              <div class="col-lg-2 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
              </div>
            </b-row>
            <b-row class="my-4 p-4">
              <div class="col-lg-3 col-md-6">
                <b-row>
                  <div class="col-lg-2 col-md-6 mb-4">
                    <b-skeleton type="avatar"></b-skeleton>
                  </div>
                  <b-col>
                    <b-skeleton width="80%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="40%"></b-skeleton>
                  </b-col>
                </b-row>
              </div>
              <div class="col-lg-2 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
              </div>
              <div class="col-lg-3 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
              </div>
              <div class="col-lg-2 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
              </div>
            </b-row>
            <b-row class="my-4 p-4">
              <div class="col-lg-3 col-md-6">
                <b-row>
                  <div class="col-lg-2 col-md-6 mb-4">
                    <b-skeleton type="avatar"></b-skeleton>
                  </div>
                  <b-col>
                    <b-skeleton width="80%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="40%"></b-skeleton>
                  </b-col>
                </b-row>
              </div>
              <div class="col-lg-2 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
              </div>
              <div class="col-lg-3 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
              </div>
              <div class="col-lg-2 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
              </div>
            </b-row>
          </template>

          <template v-if="obligasi.length > 0 || orders.length > 0">
            <ButtonListActivity @change-order="changeOrder" :orderBy="orderBy" :activeTab="activeTab"
              @change-active-tab="changeActiveTab" />
            <!-- <b-card class="list-activity-card mt-4" v-for="(value, index) in orders" :key="index">
              <div class="row">
                <div class="col-lg-6">
                  <p class="transaction-date">
                    {{ moment(value.updated_at).format(" DD MMM YYYY") }}
                  </p>
                </div>
                <div class="col-lg-6">
                  <div v-if="value.id_transaction_type === 1">
                    <div class="row">
                      <template v-if="value.promo_transaction != null">
                        <div class="col-lg-3">
                          <p class="transaction-type text-center">Pembelian</p>
                        </div>

                        <div class="col-lg-3">
                          <p class="transaction-promo text-center">
                            {{ value.promo_transaction.promo_code }}
                          </p>
                        </div>

                        <div class="col-lg-6">
                          <p class="transaction-status text-center">
                            {{
                              value.status === "waiting itrade"
                              ? "Menunggu Verifikasi"
                              : value.status === "waiting confirm"
                                ? "Menunggu Verifikasi"
                                : value.status == "waiting sales"
                                  ? "Menunggu Verifikasi Sales"
                                  : value.status == "approve"
                                    ? "Dalam Proses"
                                    : "Menunggu Pembayaran"
                            }}
                          </p>
                        </div>
                      </template>
<template v-else>
                        <div class="col-lg-6">
                          <p class="transaction-type text-center text-lg-right">
                            Pembelian
                          </p>
                        </div>
                        <div class="col-lg-6">
                          <p class="transaction-status text-center">
                            {{
                              value.status === "waiting itrade"
                              ? "Menunggu Verifikasi"
                              : value.status === "waiting confirm"
                                ? "Menunggu Verifikasi"
                                : value.status == "waiting sales"
                                  ? "Menunggu Verifikasi Sales"
                                  : value.status == "approve"
                                    ? "Dalam Proses"
                                    : "Menunggu Pembayaran"
                            }}
                          </p>
                        </div>
                      </template>
</div>
</div>
<div v-else-if="value.id_transaction_type === 2">
  <div class="row">
    <div class="col-lg-6">
      <p class="transaction-type text-center text-lg-right">
        Penjualan
      </p>
    </div>
    <div class="col-lg-6">
      <p class="transaction-status text-center">
        {{
        value.status === "waiting itrade"
        ? "Menunggu Verifikasi"
        : value.status === "waiting confirm"
        ? "Menunggu Verifikasi"
        : value.status == "waiting sales"
        ? "Menunggu Verifikasi Sales"
        : value.status == "approve"
        ? "Dalam Proses"
        : "null"
        }}
      </p>
    </div>
  </div>
</div>
<div v-else>
  <div class="row">
    <div class="col-lg-6">
      <p class="transaction-type text-center text-lg-right">
        Pengalihan
      </p>
    </div>
    <div class="col-lg-6">
      <p class="transaction-status text-center">
        {{
        value.status === "waiting itrade"
        ? "Menunggu Verifikasi"
        : value.status === "waiting confirm"
        ? "Menunggu Verifikasi"
        : value.status == "waiting sales"
        ? "Menunggu Verifikasi Sales"
        : value.status == "approve"
        ? "Dalam Proses"
        : "null"
        }}
      </p>
    </div>
  </div>
</div>
</div>
</div>

<b-row>
  <div class="col-lg-4 col-md-6 my-auto">
    <div class="fund-name">
      <b-row>
        <div class="col-lg-12 col-md-6">
          <div v-if="value.id_transaction_type == 1" class="my-auto">
            <div class="row">
              <div class="col-lg-12 col-md-6">
                <div class="row">
                  <div class="col-lg-2 col-md-6 mb-2">
                    <div v-if="value.product.image == null">
                      <img :src="'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                      value.product.fund_name
                                      " />
                    </div>
                    <div v-else>
                      <img :src="`${imageCore}/images/products/${value.product.image}`" alt="Fund Image" />
                    </div>
                  </div>
                  <div class="col-lg-10 col-md-6">
                    <div v-if="value.status != 'waiting transfer'">
                      <b-button class="btn-modal-portfolio" :to="'/order/subscription-status/' +
                                      value.code +
                                      '/' +
                                      value.fund_code
                                      ">
                        <p class="text-left mb-2">
                          Reksa Dana
                          {{
                          value.product
                          ? value.product.fund_type
                          : ""
                          }}
                        </p>
                        <h4>
                          {{
                          value.product
                          ? value.product.fund_name
                          : ""
                          }}
                        </h4>
                      </b-button>
                    </div>
                    <div v-else>
                      <b-button class="btn-modal-portfolio" :to="'/order/subscription-checkout/' +
                                        value.code +
                                        '/' +
                                        value.fund_code
                                        ">
                        <p class="text-left mb-2">
                          Reksa Dana
                          {{
                          value.product
                          ? value.product.fund_type
                          : ""
                          }}
                        </p>
                        <h4>
                          {{
                          value.product
                          ? value.product.fund_name
                          : ""
                          }}
                        </h4>
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="value.id_transaction_type == 2" class="my-auto">
            <div class="row">
              <div class="col-lg-12 col-md-6">
                <div class="row">
                  <div class="col-lg-2 col-md-6 mb-2">
                    <div v-if="value.product.image == null">
                      <img :src="'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                      value.product.fund_name
                                      " />
                    </div>
                    <div v-else>
                      <img :src="`${imageCore}/images/products/${value.product.image}`" alt="Fund Image" />
                    </div>
                  </div>
                  <div class="col-lg-10 col-md-6">
                    <b-button class="btn-modal-portfolio" :to="'/order/redeem-status/' +
                                    value.code +
                                    '/' +
                                    value.fund_code
                                    ">
                      <p class="text-left mb-2">
                        Reksa Dana
                        {{
                        value.product
                        ? value.product.fund_type
                        : ""
                        }}
                      </p>
                      <h4>
                        {{
                        value.product
                        ? value.product.fund_name
                        : ""
                        }}
                      </h4>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row">
              <div class="col-lg-12 col-md-6">
                <div class="row">
                  <div class="col-lg-2 col-md-6 mb-2">
                    <div v-if="value.product_out.image == null">
                      <img :src="'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                      value.product_out.fund_name
                                      " />
                    </div>
                    <div v-else>
                      <img :src="`${imageCore}/${value.product_out.image}`" alt="Fund Image" />
                    </div>
                  </div>
                  <div class="col-lg-10 col-md-6">
                    <div class="switch-from">
                      <p>
                        Dari Reksa Dana
                        {{
                        value.product_out
                        ? value.product_out.fund_type
                        : ""
                        }}
                      </p>
                    </div>
                    <b-button class="btn-modal-portfolio" :to="'/order/switching-status/' +
                                      value.code +
                                      '/' +
                                      value.product_out.fund_code
                                      ">
                      <h4 class="text-left">
                        {{
                        value.product_out
                        ? value.product_out.fund_name
                        : ""
                        }}
                      </h4>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-6">
                <div class="row">
                  <div class="col-lg-2 col-md-6 mb-2">
                    <div v-if="value.product_in.image == null">
                      <img :src="'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                      value.product_in.fund_name
                                      " />
                    </div>
                    <div v-else>
                      <img :src="`${imageCore}/${value.product_in.image}`" alt="Fund Image" />
                    </div>
                  </div>
                  <div class="col-lg-10 col-md-6">
                    <div class="switch-to">
                      <p>
                        Ke Reksa Dana
                        {{
                        value.product_in
                        ? value.product_in.fund_type
                        : ""
                        }}
                      </p>
                    </div>
                    <b-button class="btn-modal-portfolio" :to="'/order/switching-status/' +
                                      value.code +
                                      '/' +
                                      value.product_out.fund_code
                                      ">
                      <h4>
                        {{
                        value.product_in
                        ? value.product_in.fund_name
                        : ""
                        }}
                      </h4>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-row>
    </div>
  </div>
  <div class="col-lg-2 col-md-6 my-auto">
    <div class="investment-value">
      <div v-if="value.id_transaction_type == 1">
        <p>Nilai Investasi</p>
        <h3 class="mt-n2">
          {{ value.product ? value.product.fund_ccy : ""
          }}{{ formatPrice(value.amount_nominal) }}
        </h3>
      </div>
      <div v-else-if="value.id_transaction_type == 2">
        <p>{{ formatNab(value.amount_unit) }} unit</p>
        <h3 class="mt-n2">
          {{ value.product ? value.product.fund_ccy : "" }}
          {{
          formatPrice(
          value.amount_nominal != null
          ? value.amount_nominal
          : value.amount_unit * value.nav_per_unit
          )
          }}
        </h3>
      </div>
      <div v-else>
        <p>Nilai Investasi</p>
        <h3 class="mt-n2">
          {{ value.product_out ? value.product_out.fund_ccy : ""
          }}{{
          formatPrice(
          value.switch_out_nominal != null
          ? value.switch_out_nominal
          : value.switch_out_unit * value.nav_per_unit
          )
          }}
        </h3>
      </div>
    </div>
  </div>

  <div class="col-lg-2 col-md-6 my-auto"></div>
  <div class="col-lg-4 col-md-6 text-right my-auto">
    <div class="timer-buy">
      <b-row class="justify-content-center justify-content-lg-end">
        <div v-if="value.status == 'waiting transfer'">
          <div v-if="value.id_transaction_type == 1">
            <div class="
                              col-lg-12 col-md-6
                              my-auto
                              text-left text-lg-right
                            ">
              <b-button :to="'/order/subscription-checkout/' +
                              value.code +
                              '/' +
                              value.fund_code
                              " class="btn-buy py-2 px-4">
                Bayar
              </b-button>
            </div>
          </div>
        </div>
        <div v-else-if="value.status == 'waiting confirm' ||
                        value.status == 'approve'
                        ">
          <div class="
                            col-lg-12 col-md-6
                            my-auto
                            text-left text-lg-right
                          ">
            <div class="estimation text-left">
              <p class="ml-0">Estimasi Selesai</p>
              <h3 class="mt-n2 text-left">
                3 hari bursa <br />
                <small>setelah tanggal transaksi</small>
              </h3>
            </div>
          </div>
        </div>
      </b-row>
    </div>
  </div>
</b-row>
</b-card> -->
            <ItemReksadana :value="value" v-for="(value, index) in filterData.reksadana" :key="index"></ItemReksadana>
            <ItemObligasi :value="value" v-for="(value, index) in filterData.obligasi" :key="index + orders.length">
            </ItemObligasi>
          </template>
          <!-- Jika data kosong semua -->
          <div v-else>
            <div class="check-transaction mt-5">
              <b-row class="justify-content-center text-center">
                <b-col class="my-auto">
                  <b-button to="/reksadana" class="btn-buy px-5 py-3">
                    Mulai Investasi
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-skeleton-wrapper>
      </b-container>
    </div>
    <Footer3 />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import HeaderBottomOrders from "@/components/partials/header/HeaderBottomOrders.vue";
import Footer3 from "@/components/partials/footer/Footer3.vue";
import ButtonListActivity from '@/components/partials/button/buttonListActivity.vue';
import ItemReksadana from '@/components/partials/transactions/itemReksadana.vue';
import ItemObligasi from '@/components/partials/transactions/itemObligasi.vue';
import * as moment from "moment";
import instance from "@/utils/api";

export default {
  name: "OnProccess",
  metaInfo: {
    title: "Aktivitas Transaksi | CGS iTrade Fund",
  },
  components: {
    Header,
    HeaderBottomOrders,
    Footer3,
    ButtonListActivity,
    ItemReksadana,
    ItemObligasi
  },
  mounted() {
    setInterval(() => {
      this.date = moment(this.date.subtract(1, "seconds"));
    }, 1000);

    Promise.all([
      this.fetchOrders(),
      this.fetchObligasi(),
    ]).catch(err => {
      console.log(err);
      alert("Error");
    }).finally(() => {
      this.loading = false;
    })
  },
  data() {
    return {
      date: moment(2460 * 60 * 1000),
      orders: [],
      imageCore: process.env.VUE_APP_IMAGE_URL,

      // fbonds
      loading: true,
      activeTab: 'semua',
      obligasi: [],
      orderBy: 'DESC',
    };
  },
  computed: {
    time: function () {
      return this.date.format("HH:mm:ss");
    },
    ...mapGetters({
      user: "auth/user",
      app_url: "app_url",
      access_token: "auth/access_token",
    }),
    filterData() {
      return {
        reksadana: (this.activeTab === 'semua' || this.activeTab === 'reksadana') ? this.orders : [],
        obligasi: (this.activeTab === 'semua' || this.activeTab === 'obligasi') ? this.obligasiFilter : [],
      }
    },
    obligasiFilter() {
      if (!Array.isArray(this.obligasi)) return [];
      const newObligasi = [...this.obligasi];
      return newObligasi.sort((a, b) => {
        const aDate = new Date(a.updated_at);
        const bDate = new Date(b.updated_at);
        return this.orderBy === 'ASC' ? (aDate - bDate) : (bDate - aDate);
      })
    }
  },
  methods: {
    async fetchOrders() {
      let response = await instance({
        method: "GET",
        url: `${this.app_url}order-get?ifua_number=${(this.user.individual ? this.user.individual : this.user.institutional)?.ifua}`,
        // url: `${this.app_url}order-get?ifua_number=INV690000FPF0169`, 
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.access_token}`,
        },
      });

      this.orders = response.data.data;
    },
    async fetchObligasi() {
      let response = await instance({
        method: "GET",
        url: `${this.app_url}fbonds/list-order-transaction?client_id=${(this.user.individual ? this.user.individual : this.user.institutional)?.client_id}&action=ordertransaction`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.access_token}`,
        },
      });

      this.obligasi = response.data.data.filter(candidate => candidate.status == 0);
    },
    changeActiveTab(tab) {
      this.activeTab = tab;
    },
    changeOrder(order) {
      this.orderBy = order;
    }
  },
};
</script>

<style lang="scss" scoped>
.activity {
  background: #f3f4f6;
}

.investment-activity {
  background: white;
}

.activity-container {
  padding-top: 120px;
  margin-bottom: 0px;
}

.list-activity {
  background: #f3f4f6;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #4b5563;
}

.activity-title {
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;

    color: #111827;
  }
}

.transaction-date {
  font-weight: 700;
  font-size: 12px;
  color: #71717a;
}

.transaction-type {
  font-weight: 700;
  font-size: 12px;
  color: #71717a;
}

.transaction-promo {
  font-weight: 700;
  font-size: 12px;
  color: #2c5282;
  background: #ebf8ff;
  border-radius: 6px;
}

.transaction-status {
  font-weight: 700;
  font-size: 12px;
  color: #ce2512;
  background: #fdeeec;
  border-radius: 20px;
}

.purchased {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #e60012;
  }
}

.estimation {
  p {
    color: #6B7280 !important;
    font-family: Inter !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 150% !important;
  }

  h3 {
    color: #111827;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 24px */
  }
}

.on-proccess {
  border-bottom: 3px solid $tertiaryColor;

  a {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
    text-decoration: none;
  }
}

.finish {
  a {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
    text-decoration: none;
  }
}

.timer-buy {
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #e60012;
  }
}

.switch-from {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6b7280;
    text-align: left;
    margin: 0;
  }
}

.switch-to {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6b7280;
    text-align: left;
    margin: 0;
  }
}

.btn-buy {
  font-family: $inter;
  background: $secondaryColor;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  border: none;
  color: white;
}

.btn-buy:hover {
  background: $hoverSecondary;
}

.btn-buy:focus {
  box-shadow: none !important;
}

.btn-buy:active {
  background: $hoverSecondary !important;
  outline: none !important;
}

.btn-modal-portfolio {
  font-family: $inter;
  background: transparent;
  padding: 0;
  border: none;
}

.btn-modal-portfolio:hover {
  background: transparent !important;
}

.btn-modal-portfolio:focus {
  box-shadow: none !important;
  background: transparent !important;
}

.btn-modal-portfolio:active {
  background: transparent !important;
  outline: none !important;
}

@media only screen and (max-width: 768px) {
  h4 {
    font-size: 14px !important;
  }

  h3 {
    font-size: 14px !important;
  }

  p {
    font-size: 14px !important;
  }
}
</style>
